
import { useState, useEffect, useRef, useMemo } from "react";

export const useCoinCapWebSocket = () => {
  const [message, setMessage] = useState(null);
  const webSocketRef = useRef(null);
  const url = "wss://ws.coincap.io/prices?assets=bitcoin";
  const memoizedUrl = useMemo(() => url, []);

  useEffect(() => {
    let keepAliveInterval;
    const connectWebSocket = () => {
      if (webSocketRef.current && webSocketRef.current.readyState !== WebSocket.CLOSED) {
        return;
      }
      webSocketRef.current = new WebSocket(memoizedUrl);
      webSocketRef.current.onopen = () => {
        // console.log("WebSocket connection opened:", memoizedUrl);

        keepAliveInterval = setInterval(() => {
          if (webSocketRef.current.readyState === WebSocket.OPEN) {
            webSocketRef.current.send('ping');
          }
        }, 9000); // Send a ping message
      };

      webSocketRef.current.onmessage = (event) => {
        setMessage(event.data);
      };

      webSocketRef.current.onerror = (event) => {
        console.error("WebSocket error:", event);
      };

      webSocketRef.current.onclose = (event) => {
        // console.log("WebSocket connection closed:", event);
        // console.log("Reason:", event.reason);
        clearInterval(keepAliveInterval);
        setTimeout(() => {
          // console.log("Attempting to reconnect...");
          connectWebSocket();
        }, 9000); // Change to 9 seconds or a higher value
      };
    };
    connectWebSocket();
    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
      clearInterval(keepAliveInterval);
    };
  }, [memoizedUrl]);
  return { message };
};
